



























































































































































import Vue from "vue";
import { Component } from "nuxt-property-decorator";
import { faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Subscription } from "~/types/payment";
import PaymentButton from "~/components/buttons/PaymentButton.vue";

@Component({
  name: "PaymentSuccessModal",
  components: { PaymentButton },
})
export default class PaymentSuccessModal extends Vue {
  type: Subscription | null = null;

  get faTimes() {
    return faTimes;
  }

  get faInfoCircle() {
    return faInfoCircle;
  }

  get premiumFeatures() {
    return [
      this.$tc("pages.pricing.premium.feature_2"),
      this.$tc("pages.pricing.premium.feature_3"),
      this.$tc("pages.pricing.premium.feature_7"),
    ];
  }

  get unlimitedFeatures() {
    return [
      this.$tc("pages.pricing.lifelong.feature_2"),
      this.$tc("pages.pricing.lifelong.feature_3"),
      this.$tc("pages.pricing.lifelong.feature_7"),
    ];
  }

  onClickPricing() {
    this.onClose();
    this.$router.push("/mein-vanausbau/pricing");
  }

  onClickCashback() {
    this.onClose();
    this.$router.push("/mein-vanausbau/plan-and-share");
  }

  onClose() {
    if (process.browser && window) {
      window.location.hash = "";
      this.$emit("close");
    }
  }
}
