





















import Vue from "vue";
import { Component, Prop } from "nuxt-property-decorator";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Subscription } from "~/types/payment";

@Component({
  name: "PaymentButton",
})
export default class PaymentButton extends Vue {
  loading = false;
  isLoadingSession = false;

  @Prop({
    type: String,
    default: null,
  })
  type!: Subscription | null;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  get sessionId() {
    return this.$store.getters["payments/paymentSessionId"];
  }

  get faAngleRight() {
    return faAngleRight;
  }

  get publishableKey() {
    return process.env.STRIPE_PUBLISHABLE_KEY;
  }

  async onSubmit() {
    this.isLoadingSession = true;

    const errorMessage = await this.$store.dispatch(
      "payments/createSession",
      this.type
    );

    if (errorMessage) {
      this.$notify({ type: "error", message: errorMessage });
      this.isLoadingSession = false;
      return;
    }

    // You will be redirected to Stripe's secure checkout page
    if (this.$refs.checkoutRef) {
      (this.$refs.checkoutRef as any).redirectToCheckout();
    }

    this.isLoadingSession = false;
  }
}
